
import settings from "../package.json";


const getApi = async path => {
    let rr = localStorage.getItem("access_token");
    if(rr !== "undefined" && path !== "auth/") {
        let res = await fetch(settings.api_endpoint + '/' + path, {
            headers: {Authorization: "Bearer " + rr}
        });
        let result = await res;
        if (result.status === 403) {
            localStorage.clear()
            window.location.href="/"
        }
        return await result.json();
    }
    if(rr === "undefined"){
        localStorage.clear()
        window.location.href="/"
    }
}
const postApi = async (path, data) => {
    let rr = localStorage.getItem("access_token");
    if((rr !== "undefined" || path === "auth/")) {
        let res = await fetch(settings.api_endpoint + "/" + path, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {Authorization: "Bearer " + rr, "Content-Type": 'application/json'}
        });
        let result = await res;
        if (result.status === 403) {
            localStorage.clear()
            window.location.href="/"
        }
        return await result.json();
    }
}
export default getApi;
export {postApi};