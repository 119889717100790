import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Icon} from "@mui/material";
import {HighlightOff, CheckCircleOutlined} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Online = () => {
    const [data, setData] = useState([])
    const [ranks, setRanks] = useState([])
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);

    const getData = async () => {
        setData(await Api('admin/online/'+project))
        setRanks(await Api('admin/staff/ranks/'+project))
    }
    //eslint-disable-next-line
    useEffect(()=>{getData()}, [project])

    const clearOnline = () => {
        postApi(`admin/online/clear/${project}`).then(()=>{getData()});
    }

    const resolveRank = id => {
        let r = ranks.filter(i=>i.RankID === id)
        if(r[0])
            return r[0].Name
        return "N/A"
    }

    const toHR = seconds => {
        let days = Math.floor(seconds/86400);
        let hours = Math.floor((seconds%86400)/3600);
        let minutes = Math.floor(((seconds%86400)%3600)/60);
        let s = Math.floor(((seconds%86400)%3600)%60);
        return days+" дней, "+hours+" часов, " + minutes +" минут и "+s+" секунд";
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Отработка нормы онлайн направления {project.toUpperCase()}
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 0, p:2 }}>
                    {permissions.indexOf('clear_online')>-1 &&
                        <Button variant={'outlined'} onClick={clearOnline} color={'success'}>Обнулить онлайн</Button>
                    }
                </Box>
            </Box>
            <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
                <Table stickyHeader aria-label="sticky table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Никнейм</TableCell>
                            <TableCell>Должность</TableCell>
                            <TableCell>Время онлайн</TableCell>
                            <TableCell>Выполнение нормы</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((i,e)=><StyledTableRow key={'onlinerow_'+project+'_'+e}>
                                <TableCell>{i.Name}</TableCell>
                                <TableCell>{resolveRank(i.Rank)}</TableCell>
                                <TableCell>{toHR(i.Time)}</TableCell>
                                <TableCell>
                                    <Icon>{i.Complete ? <CheckCircleOutlined color={'success'}/> : <HighlightOff color={'error'} />}</Icon>
                                </TableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default Online