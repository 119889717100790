import * as React from 'react';
import {Link, Outlet} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useEffect, useState} from "react";
import Api from "../Api";
import {Collapse, Icon, useMediaQuery} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";
import {useSelector, useDispatch} from "react-redux";
import {setProject} from "../features/SessionSlice";
import {setP} from "../features/PermissionSlice";
import {Logout, Settings} from "@mui/icons-material";

const drawerWidth = 330;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const loggedIn = localStorage.hasOwnProperty("access_token");

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function ListItemLink(props) {
    return <ListItemButton component={Link} {...props} />;
}

export default function PortalLayout() {
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState([]);
    const [sopen, setSopen] = useState(false);
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);
    const dispatch = useDispatch()
    const mobile = useMediaQuery('(min-width:600px)')
    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleClick = () => setSopen(!sopen);

    const getMenu = async () => {
        setMenu(await Api('admin/menu/'+project))
    };
    const getPermissions = async () => {
        dispatch(setP(await Api(`admin/permissions/${project}`)))
    }

    const reclaimToken = async () => {
        let token = await Api('auth/reclaim');
        if(token) {
            //console.log("setting new token",token.access_token)
            localStorage.setItem("access_token", token.access_token);
        }
    }


    useEffect(()=>{
        let intId = -1;
        let ttimer = -1;
        if(loggedIn) {
            getMenu();
            getPermissions();
            intId = setInterval(reclaimToken, 300000)
            ttimer = setTimeout(reclaimToken, 1000);
        }
        return function cleanup(){
            clearInterval(intId);
            clearTimeout(ttimer);
        }
    //eslint-disable-next-line
    },[project])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    {loggedIn &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>}
                    {mobile && <Typography variant="h6" noWrap component="div" sx={{display: {xs: 'none', md:'flex'} }}>
                        Административный портал FS Project
                    </Typography>}
                    {loggedIn &&
                    <Box sx={{ marginLeft: 2, flexGrow: 1, display: 'flex' }}>
                        <Button
                            sx={{ my: 2, color: 'white', display: 'block' }}
                            variant={project === 'mfs' ? 'contained' : 'outlined'}
                            color={project === 'mfs' ? 'success' : 'primary'}
                            size={mobile ? 'large':'small'}
                            onClick={() => dispatch(setProject('mfs'))}
                        >
                            MFS
                        </Button>
                        <Button
                            sx={{ marginLeft: 1, my: 2, color: 'white', display: 'block' }}
                            variant={project === 'tfs' ? 'contained' : 'outlined'}
                            color={project === 'tfs' ? 'success' : 'primary'}
                            size={mobile ? 'large':'small'}
                            onClick={() => dispatch(setProject('tfs'))}
                        >
                            TFS
                        </Button>
                        <Button
                            sx={{ marginLeft: 1, my: 2, color: 'white', display: 'block' }}
                            variant={project === 'support' ? 'contained' : 'outlined'}
                            color={project === 'support' ? 'success' : 'primary'}
                            size={mobile ? 'large':'small'}
                            onClick={() => dispatch(setProject('support'))}
                        >
                            СП
                        </Button>
                        <Button
                            sx={{ marginLeft: 1, my: 2, color: 'white', display: 'block' }}
                            variant={project === 'hr' ? 'contained' : 'outlined'}
                            color={project === 'hr' ? 'success' : 'primary'}
                            size={mobile ? 'large':'small'}
                            onClick={() => dispatch(setProject('hr'))}
                        >
                            HR
                        </Button>
                    </Box>
                    }
                    {loggedIn &&
                    <Box sx={{ flexGrow: 0 }}>
                            <IconButton
                                color="inherit"
                                edge="start"
                                sx={{
                                    marginRight: 0,
                                }}
                                href={'/logout'}
                            >
                                <Logout />
                            </IconButton>
                    </Box>
                    }
                </Toolbar>
            </AppBar>
            {loggedIn &&
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    FS PROJECT
                </DrawerHeader>
                <Divider />
                <List>
                    {menu.map((i, index) => {
                        if (i === "divider") return <Divider key={'menuItem_' +project+'_' + index}/>
                        if(i.hasOwnProperty('items') && i.items){
                            let retval = []
                            retval.push(<ListItemButton onClick={handleClick} key={'root_item1'+project}
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? 'initial' : 'left',
                                                            px: 2.5,
                                                        }}>
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <Icon>{i.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={i.text} sx={{opacity: open ? 1 : 0}} />
                                {sopen ? <ExpandLess sx={{opacity: open ? 1 : 0}} /> : <ExpandMore sx={{opacity: open ? 1 : 0}} />}
                            </ListItemButton>)
                            retval.push(
                                <Collapse in={sopen} timeout="auto" unmountOnExit key={'root_item_2'+project}>
                                    <List component="div">
                                        {i.items.map((ii,ee)=>{
                                            return <ListItemLink to={ii.route} key={'submenu_'+project+'_'+ee} sx={{marginLeft: 4}}>
                                                <ListItemIcon sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Icon fontSize={'small'}>{ii.icon}</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary={ii.text} />
                                            </ListItemLink>
                                        })}
                                    </List>
                                </Collapse>)
                            return retval
                        }
                        else {
                            return <ListItemLink to={i.route} key={"m_e" +project+'_'+ index}
                                          sx={{
                                              minHeight: 48,
                                              justifyContent: open ? 'initial' : 'center',
                                              px: 2.5,
                                          }}
                                          title={i.text}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Icon>{i.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={i.text} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemLink>
                        }
                    })}
                    {permissions.indexOf('manage_permissions') > -1 &&
                        <ListItemLink to={'/permissions'}
                                      sx={{
                                          minHeight: 48,
                                          justifyContent: open ? 'initial' : 'center',
                                          px: 2.5,
                                      }}
                                      title={'Настройки прав доступа'}
                        >
                            <ListItemIcon sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}>
                                <Settings/>
                            </ListItemIcon>
                            <ListItemText primary={'Настройка прав доступа'} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemLink>
                    }
                </List>
            </Drawer>}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet/>
            </Box>
        </Box>
    );
}