import { configureStore } from '@reduxjs/toolkit'
import permissionReducer from "./features/PermissionSlice";
import sessionReducer from "./features/SessionSlice"

export default configureStore({
    reducer: {
        permissions: permissionReducer,
        session: sessionReducer,
    }
})
