import { createSlice } from '@reduxjs/toolkit'


export const permissionSlice = createSlice({
    name: 'permissions',
    initialState: {
        value: []
    },
    reducers: {
        clear: state => {state.value = []},
        setP: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { clear, setP } = permissionSlice.actions

export default permissionSlice.reducer