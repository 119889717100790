import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Api from "../Api";
import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import {TableFooter, TablePagination} from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Log = () => {
    const project = useSelector(state => state.session.project);
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(25);
    const getData = async () => {setData(await Api(`admin/log/${project}/${page}/${rows}`))}
    //eslint-disable-next-line
    useEffect(()=>{getData()}, [project, page, rows])
    return <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Typography variant={'h5'} sx={{p:2}}>Журнал действий в направлении {project.toUpperCase()}</Typography>
                <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
                    <Table stickyHeader aria-label="sticky table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Дата и время</TableCell>
                                <TableCell>Администратор</TableCell>
                                <TableCell>Модуль</TableCell>
                                <TableCell>Действие</TableCell>
                                <TableCell>Поле</TableCell>
                                <TableCell>Старое значение</TableCell>
                                <TableCell>Новое значение</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((i,e)=><StyledTableRow key={`log_${e}`}>
                                <TableCell>{moment(i.inserted_date).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                                <TableCell>{i.admin_nick}</TableCell>
                                <TableCell>{i.module}</TableCell>
                                <TableCell>{i.action}</TableCell>
                                <TableCell>{i.field}</TableCell>
                                <TableCell>{i.old_value}</TableCell>
                                <TableCell>{i.new_value}</TableCell>
                            </StyledTableRow>)}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                component="div"
                                count={100}
                                page={page}
                                onPageChange={(e,p)=>{setPage(p)}}
                                rowsPerPage={rows}
                                onRowsPerPageChange={(e)=>{setRows(e.target.value); setPage(0)}}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>
    </Paper>
}
export default Log;