import Typography from "@mui/material/Typography";
import * as React from "react";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Api, {postApi} from "../Api";
import Grid from "@mui/material/Grid";
import {Switch} from "@mui/material";
import Box from "@mui/material/Box";
import JoditEditor from 'jodit-react';

const Content = () => {
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);
    const [data, setData] = useState({page_header: 'Загружается'})
    const [editMode, setEditMode] = useState(false);
    const {pageName} = useParams()
    const editor = useRef(null);
    const config = {
        readonly: false,
        theme: 'dark',
        enter: 'BR'
    }
    const getData = async () => {setData(await Api(`admin/content/${pageName}/${project}`))}
    //eslint-disable-next-line
    useEffect(()=>{getData()}, [pageName, project])

    const setContent = content => {
        postApi(`admin/content/${pageName}/${project}`,{page_header: data.page_header, page_content: content}).then(()=>{
            getData()
        })
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                    <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                        <Typography variant={'h5'} sx={{p:2}}>{data.page_header}</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, p:2 }}>
                        {permissions.indexOf('edit_content') > -1 &&
                            <Switch checked={editMode} onChange={()=>{setEditMode(!editMode)}}/>
                        }
                    </Box>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            {!editMode && <Paper sx={{ width: '100%', overflowY: 'scroll', p: 2}} className={'contenttable'}
                   dangerouslySetInnerHTML={{__html: data.page_content}}>
            </Paper>
            }
            {editMode &&
                <JoditEditor
                    ref={editor}
                    value={data.page_content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {}}
                />
            }
        </Grid>
    </Grid>
}
export default Content