import { createSlice } from '@reduxjs/toolkit'


export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        jwttoken: null,
        project: localStorage.project || 'mfs',
    },
    reducers: {
        clearToken: state => {state.jwttoken = null},
        clearProject: state => {state.project = 'mfs'},
        setToken: (state, action) => {
            state.jwttoken = action.payload
        },
        setProject: (state, action) => {
            state.project = action.payload
            localStorage.project = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { clearID, setID, clearProject, setProject } = sessionSlice.actions

export default sessionSlice.reducer