import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    ListItem,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import * as React from "react";
import {useSelector} from "react-redux";

const Blacklist = () => {
    const lists = [
        {id:1, name:'Черный список игроков'},
        {id:2, name:'Черный список администрации'},
        {id:3, name:'Черный список MFS RP'},
        {id:4, name:'Черный список TFS RP'},
    ]
    const [activeList, setActiveList] = useState(1)
    const [items, setItems] = useState([])
    const [addOpen, setAddOpen] = useState(false)
    const [steamid, setSteamId] = useState('')
    const [nickname, setNickname] = useState('')
    const [reason, setReason] = useState('')
    const [addinlists, setAddInLists] = useState([])
    const [removeItem, setRemoveItem] = useState({})
    const permissions = useSelector(state=>state.permissions.value)

    const getData = async () => {
        setItems(await Api(`hr/blacklist/get-blacklist/${activeList}`))
    }
    const addInList = async () => {
        postApi(`hr/blacklist/add-blacklist`,{nickname: nickname,
            steam: steamid, reason: reason, type:addinlists}).then(()=>{setAddOpen(false); getData()})
    }
    const removeFromList = async () => {
        postApi(`hr/blacklist/delete-blacklist/${removeItem.id}`).then(()=>{setRemoveItem({}); getData()});
    }
    //eslint-disable-next-line
    useEffect(()=>{getData()}, [activeList])
    //eslint-disable-next-line
    useEffect(()=>{setActiveList(1);}, [])

    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Управление черным списком</Typography>
                </Box>
                <Box sx={{ flexGrow: 10, p:2 }}>
                    <Select size={'small'}
                            value={activeList}
                            onChange={(e)=>{setActiveList(e.target.value)}}
                            sx={{width: '400px'}}
                    >
                        {lists.map((i,e)=><MenuItem key={'list_'+e} value={i.id}>{i.name}</MenuItem>)}
                    </Select>
                </Box>
                {permissions.indexOf('view_blacklist') > -1 && permissions.indexOf('add_blacklist') > -1 &&
                <Box sx={{ flexGrow: 0, p:2 }}>
                    <Button variant={'contained'} color={'warning'} onClick={()=>{setAddOpen(true)}}>ДОБАВИТЬ</Button>
                </Box>
                }
            </Box>
        </Paper>
        {permissions.indexOf('view_blacklist') > -1 &&
        <Paper sx={{ width: '100%', overflow: 'auto', mt:1}}>
            <List>
                {items.map((i,e)=><ListItem key={`bl_item_${e}`} alignItems={'flex-start'} divider>
                    <ListItemText sx={{width: '100px', textAlign: 'left', flex: '0 0 auto'}}>{i.id}</ListItemText>
                    <ListItemText sx={{width: '20%', textAlign: 'left', flex: '0 0 auto'}}>{i.nickname}</ListItemText>
                    <ListItemText sx={{width: '20%', textAlign: 'left', flex: '0 0 auto'}}>{i.steam_id64}</ListItemText>
                    <ListItemText sx={{width: '40%', textAlign: 'left', flex: '0 0 auto'}}>{i.reason}</ListItemText>
                    <ListItemText sx={{textAlign: 'left'}}>
                        {permissions.indexOf('delete_blacklist') > -1 &&
                        <Button variant={'outlined'} color={'success'} onClick={()=>{
                            setRemoveItem(i)
                        }}>Разблокировать</Button>
                        }
                    </ListItemText>
                </ListItem>)}
            </List>
        </Paper>
        }
        {permissions.indexOf('view_blacklist') === -1 && <Paper sx={{width: '100%', mt: 1, p:1}}>
            <Typography color={'error'}>У вас нет права просмотра черного списка</Typography>
        </Paper>}
        {permissions.indexOf('add_blacklist') > -1 &&
        <Dialog
            open={addOpen}
            onClose={()=>{setAddOpen(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Добавление игрока в Черный Список"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={'span'} id="alert-dialog-description">
                    <TextField fullWidth value={steamid} placeholder="STEAMID64 (длинное число)" onChange={(e)=>{setSteamId(e.target.value)}}/>
                    <TextField fullWidth value={nickname} placeholder="Никнейм" onChange={(e)=>{setNickname(e.target.value)}}/>
                    <TextField fullWidth value={reason} placeholder="Причина для внесения" onChange={(e)=>{setReason(e.target.value)}}/>
                    {lists.map((i,e)=><FormControlLabel key={'listbox_'+e}
                        label={i.name}
                        control={<Checkbox
                            checked={addinlists.indexOf(i.id) > -1}
                            onChange={(e)=>{
                                let al = structuredClone(addinlists);
                                if(e.target.checked){
                                    al.push(i.id);
                                }
                                else{
                                    al.splice(al.indexOf(i.id),1);
                                }
                                setAddInLists(al)
                            }}/>}
                    />)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setAddOpen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                <Button onClick={addInList} color={'error'} variant={'contained'}>
                    Внести!
                </Button>
            </DialogActions>
        </Dialog>
        }
        {permissions.indexOf('delete_blacklist') > -1 &&
        <Dialog
            open={removeItem.hasOwnProperty('id')}
            onClose={()=>{setRemoveItem({})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Вынесение игрока из ЧС"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={'span'} id="alert-dialog-description">
                    Действительно вынести <Typography component={'span'} color={'yellow'}>{removeItem.nickname}</Typography> из черного списка?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setRemoveItem({})}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                <Button onClick={removeFromList} color={'error'} variant={'contained'}>
                    Да!
                </Button>
            </DialogActions>
        </Dialog>
        }
    </div>
}

export default Blacklist