import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {blueGrey, grey, yellow} from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import queryString from 'query-string'
import Monitoring from "./pages/Monitoring";
import LoginPage from "./pages/LoginPage";
import PortalLayout from "./pages/PortalLayout";
import Staff from "./pages/Staff";
import Online from "./pages/Online";
import Profile from "./pages/Profile";
import Rules from "./pages/Rules";
import Whitelist from "./pages/Whitelist";
import Content from "./pages/Content";
import Log from "./pages/Log"
import Permissions from "./pages/Permissions";
import ListCvs from "./pages/DoneCvs";
import HRStats from "./pages/HRStats";
import Blacklist from "./pages/Blacklist";
import HrDashboard  from "./pages/HrDashboard";
import Constructor from "./pages/Constructor";
import {postApi} from "./Api";
import { Provider } from 'react-redux'
import ReduxStore from "./ReduxStore";
import SteamID from "steamid";
import './content_page.css';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            ...blueGrey,
            ...(mode === 'dark' && {
                main: blueGrey[300],
            }),
        },
        info: {
            ...yellow,
            ...{ main: yellow[300]}
        },
        ...(mode === 'dark' && {
            background: {
                default: blueGrey[900],
                paper: blueGrey[900],
            },
        }),
        text: {
            ...(mode === 'light'
                ? {
                    primary: grey[900],
                    secondary: grey[800],
                }
                : {
                    primary: '#fff',
                    secondary: grey[500],
                }),
        },
    },
});
const darkModeTheme = createTheme(getDesignTokens('dark'));

const obtainToken = async steamParams => {
    let token = await postApi("auth/", steamParams);
    localStorage.setItem("access_token", token['access_token']);
    window.location.href="/";
}
const s64to32 = steamID64 => {
    let sid = new SteamID(steamID64)
    return sid.getSteam2RenderedID();
}

const Auth = () => {
    const queryParams = queryString.parse(window.location.search);
    const newQP = {}
    for(let k in queryParams){
        if(k === 'openid.identity'){
            localStorage.setItem("myid",s64to32(queryParams[k].split("/")[5]))
        }
        newQP[k.replace("openid.","openid_")] = queryParams[k];
    }
    obtainToken(newQP);
    return null;
}
const Logout = () => {
    localStorage.clear();
    window.location.href='/';
    return null;
}

const loggedIn = localStorage.hasOwnProperty("access_token");
const Router = () => {
    return <BrowserRouter>
        <ThemeProvider theme={darkModeTheme}>
            <Provider store={ReduxStore}>
            <Routes>
                <Route path='/' element={<PortalLayout />}>
                    <Route path='/' element={loggedIn ? <Monitoring /> : <LoginPage />}/>
                    <Route path='monitoring' element={<Monitoring />} />
                    <Route path='staff' element={<Staff />} />
                    <Route path='profile/:steamid' element={<Profile />} />
                    <Route path='online' element={<Online />} />
                    <Route path='rules' element={<Rules />} />
                    <Route path='whitelist' element={<Whitelist />} />
                    <Route path='manual/:pageName' element={<Content />} />
                    <Route path='log' element={<Log />} />
                    <Route path='permissions' element={<Permissions />} />
                    <Route path='done_cvs' element={<ListCvs checkstatus={4} />} />
                    <Route path='decline_cvs' element={<ListCvs checkstatus={2} />} />
                    <Route path='overall' element={<HRStats />} />
                    <Route path='blacklist' element={<Blacklist />} />
                    <Route path='constructor' element={<Constructor />} />
                    <Route path='hr/view/:viewcvid' element={<HrDashboard />} />
                    <Route path='login' element={<LoginPage />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path='auth/' element={<Auth />} />
                </Route>
            </Routes>
            </Provider>
        </ThemeProvider>
    </BrowserRouter>
}

export default Router;